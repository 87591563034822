<template>
    <div>
        <!-- Hero Section -->
        <div class="relative p-8 overflow-hidden bg-no-repeat bg-cover" style="background-image: url('img/hero-3.jpg')">
            <div class="text-center my-6 relative">
                <div class="text-6xl font-bold mb-1 text-white">Bring Out The Best</div>
                <div class="text-6xl text-blue-400 font-bold mb-4">In Your Manufacturing Systems</div>
                <p class="mt-0 mb-3 line-height-3 text-center mx-auto text-blue-100" style="max-width:500px">At Continuum Automation, we strive to provide the highest quality software and controls integration systems for our clients to improve efficiency and reduce downtime.</p>
<!--
                <div class="flex align-items-center justify-content-center flex-wrap">
                    <Button label="Contact" class="p-3 mt-3" style="border-radius: 40px" @click="()=>$router.push('/contact')"/>
                </div>
-->
            </div>
        </div>

        <!-- Features Section -->
        <div class="surface-section px-4 py-8 md:px-6 lg:px-8 text-center">
            <div class="mb-3 font-bold text-2xl">
                <span class="text-900 text-5xl">Manufacturing Automation</span>
                <span class="text-blue-600 text-5xl"> Done Right</span>
            </div>
            <div class="text-700 text-xl mb-6">Although our customer requirements are unique, the results should be the same. Client satisfaction and high quality deliverables.</div>
            <div class="grid">
                <div class="col-12 md:col-4 mb-4 px-5">
                    <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                        <i class="pi pi-server text-4xl text-blue-500"></i>
                    </span>
                    <div class="text-900 mb-3 font-medium">Diverse HMI/PLC/SQL Experience</div>
                    <span class="text-700 text-sm line-height-3">Our team members are familiar with multiple industry leading SCADA platforms, PLC Vendors, and database options. We can provide quality controls systems based on a wide variety of client software preferences or requirements.</span>
                </div>
                <div class="col-12 md:col-4 mb-4 px-5">
                    <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                        <i class="pi pi-desktop text-4xl text-blue-500"></i>
                    </span>
                    <div class="text-900 mb-3 font-medium">High Performance HMI Designs</div>
                    <span class="text-700 text-sm line-height-3">Our design philosophy revolves around high performance HMI screens and overview dashboards to ensure that your operators receive the information they need in the most efficient way possible.</span>
                </div>
                <div class="col-12 md:col-4 mb-4 px-5">
                    <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                        <i class="pi pi-chart-line text-4xl text-blue-500"></i>
                    </span>
                    <div class="text-900 mb-3 font-medium">Alarming/Reporting/KPI Data Analysis</div>
                    <span class="text-700 text-sm line-height-3">Our controls systems are designed to provide the alarms necessary for safety and performance during production, and the reporting and data analysis solutions that allow management to track and analyze important KPI data.</span>
                </div>
                <div class="col-12 md:col-4 mb-4 px-5">
                    <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                        <i class="pi pi-globe text-4xl text-blue-500"></i>
                    </span>
                    <div class="text-900 mb-3 font-medium">24/7 & Global Support</div>
                    <span class="text-700 text-sm line-height-3">Our team is capable of providing 24/7 support and can also provide global support coverage based on the needs of our clients.</span>
                </div>
                <div class="col-12 md:col-4 mb-4 px-5">
                    <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                        <i class="pi pi-users text-4xl text-blue-500"></i>
                    </span>
                    <div class="text-900 mb-3 font-medium">Small Team Size</div>
                    <span class="text-700 text-sm line-height-3">We keep a small team size to ensure that we can provide the best experience and support for our clients. When you have a question or contact us for support, you'll be talking directly with a member who is intimately familiar with your system and situation.</span>
                </div>
                <div class="col-12 md:col-4 md:mb-4 mb-0 px-3">
                    <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                        <i class="pi pi-shield text-4xl text-blue-500"></i>
                    </span>
                    <div class="text-900 mb-3 font-medium">Rigorous Quality and Testing</div>
                    <span class="text-700 text-sm line-height-3">All of our projects undergo rigorous FAT and SAT testing before going live, to ensure a quality experience for your operators and reduce downtime during production.</span>
                </div>
            </div>
        </div>
        <Divider />
        <!-- Team Section
        <div class="surface-ground px-4 py-8 md:px-6 lg:px-8">
            <div class="grid">
                <div class="col-12 lg:col-4 pr-0 lg:pr-4">
                    <div class="text-900 text-5xl font-bold mb-3">Meet our team</div>
                    <p class="text-700 text-lg line-height-3">Enim diam vulputate ut pharetra sit amet aliquam id. Consequat nisl vel pretium lectus quam id leo in. 
                        Nibh sit amet commodo nulla facilisi nullam vehicula. Nunc eget lorem dolor sed viverra ipsum nunc aliquet.</p>
                </div>
                <div class="col-12 lg:col-8">
                    <div class="grid">
                        <div class="col-12 lg:col-6 p-3">
                            <img src="img/patrick.jpg" width="300" height="300" class="mb-4" />
                            <div class="font-medium text-xl mb-1 text-900">Patrick Wakefield</div>
                            <span class="text-600 font-medium">HMI/PLC Specialist</span>
                            <p class="line-height-3 mt-3 mb-3">Risus feugiat in ante metus dictum. Et egestas quis ipsum suspendisse ultrices. 
                                Risus pretium quam vulputate dignissim suspendisse. Justo nec ultrices dui sapien.</p>
                        </div>
                        <div class="col-12 lg:col-6 p-3">
                            <img src="img/alec.jpg" width="300" height="300" class="mb-4" />
                            <div class="font-medium text-xl mb-1 text-900">Alec Weaver</div>
                            <span class="text-600 font-medium">HMI/Database Specialist</span>
                            <p class="line-height-3 mt-3 mb-3">Donec et odio pellentesque diam volutpat commodo sed egestas. 
                                Blandit massa enim nec dui nunc mattis enim ut tellus. Suspendisse faucibus interdum posuere lorem ipsum.</p>
                        </div>
                        <div class="col-12 lg:col-6 p-3">
                            <img src="img/aaron.jpg" class="mb-4" />
                            <div class="font-medium text-xl mb-1 text-900">Aaron Fritts</div>
                            <span class="text-600 font-medium">PLC Specialist</span>
                            <p class="line-height-3 mt-3 mb-3">Pretium aenean pharetra magna ac placerat vestibulum. 
                                Est ullamcorper eget nulla facilisi etiam dignissim diam quis enim. Fringilla urna porttitor rhoncus dolor purus non enim..</p>
                        </div>
                            <div class="col-12 lg:col-6 p-3">
                            <img src="img/JoshuaSmart.png" class="mb-4" />
                            <div class="font-medium text-xl mb-1 text-900">Josh Smart</div>
                            <span class="text-600 font-medium">Printer Support Specialist</span>
                            <p class="line-height-3 mt-3 mb-3">While his true love is troubleshooting printer drivers and sniffing ink toner, Josh is also the manager of the company fantasy football league!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Divider />
        -->
        <!-- Software Specialties Section -->
        <div class="surface-ground">
            <br>
            <div class="mb-3 mt-3 font-bold text-2xl">
                <span class="text-900 text-5xl">Software Services</span>
            </div>
            <div class="text-700 text-xl mb-6">Over the years, our engineers and specialists have been contracted to develop tools and integrations using a wide selection of products and software. The following list is just a sample of the software we have worked with in the past, and we are always willing to work with new software if it better fits our clients' needs.</div>
            <Fieldset class="surface-ground" legend="SCADA/HMI" :toggleable="true" :collapsed="false">
                <div class="grid flex-wrap justify-content-center">
                    <div class="col-12 lg:col-4 p-3">
                        <div class="shadow-2 border-round surface-card mb-3 h-full flex-column justify-content-between flex">
                            <div class="p-4">
                                <div class="flex align-items-center">
                                    <span class="inline-flex border-circle align-items-center justify-content-center mr-3" style="width:100px;height:100px">
                                        <img src="img/icons/aveva-logo.png" alt="Image" class="w-full">
                                    </span>
                                    <span class="text-900 font-large text-2xl">System Platform</span>
                                </div>
                                <div class="text-900 my-3 text-xl font-medium">Responsive, standards-driven, and scalable software for deploying advanced SCADA, MES, and IIoT.</div>
                                <p class="mt-0 mb-3 text-700 line-height-3">AVEVA System Platform with Operations Management Interface (OMI) is the world’s only responsive, standards-driven, and scalable software for deploying advanced SCADA, MES, and IIoT that contextualizes operations processes across the organization. System Platform provides a collaborative, standards-based foundation that unifies people, processes, and assets across all facilities for continuous operational improvement and real-time decision support.</p>
                            </div>
                            <div class="px-4 py-3 surface-100 flex justify-content-between flex-wrap">
                                <!-- <Button icon="pi pi-arrow-down" label="View Our Demo" @click="()=>$router.push('/product')" class="p-button-rounded"></Button> -->
                                <Button icon="pi pi-external-link" label="Visit Website" @click="externalSite('https://www.aveva.com/en/products/system-platform/')" class="p-button-rounded p-button-success"></Button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 lg:col-4 p-3">
                        <div class="shadow-2 border-round surface-card mb-3 h-full flex-column justify-content-between flex">
                            <div class="p-4">
                                <div class="flex align-items-center">
                                    <span class="inline-flex border-circle align-items-center justify-content-center mr-3" style="width:150px;height:100px">
                                        <img src="img/icons/Ignition8.1-Full.png" alt="Image" class="w-full">
                                    </span>
                                    <span class="text-900 font-large text-2xl">Ignition</span>
                                </div>
                                <div class="text-900 my-3 text-xl font-medium">The New SCADA</div>
                                <p class="mt-0 mb-3 text-700 line-height-3">Ignition SCADA by Inductive Automation® combines an unlimited licensing model, with instant web-based deployment, and the industry-leading toolset for supervisory control and data acquisition (SCADA) — all on one open and scalable universal platform. Ignition is The New SCADA because it solves all the major pain points of old SCADA . Ignition empowers your business to easily control your processes, and track, display, and analyze all your data, without limits. </p>
                            </div>
                            <div class="px-4 py-3 surface-100 flex justify-content-between flex-wrap">
                                <!-- <Button icon="pi pi-arrow-down" label="View Our Demo" @click="()=>$router.push('/product')" class="p-button p-button-rounded"></Button> -->
                                <Button icon="pi pi-external-link" label="Visit Website" @click="externalSite('https://inductiveautomation.com/')" class="p-button-rounded p-button-success"></Button>
                            </div>
                        </div>
                    </div>
                </div>
                <Divider />
                <div class="surface-card shadow-2 border-round p-4">
                    <div class="flex justify-content-between align-items-center mb-5">
                        <span class="text-xl text-900 font-medium">Additional Services</span>
                    </div>
                    <ul class="list-none p-0 m-0">
                        <li class="grid">
                            <span class="col-2 inline-block text-900 font-medium text-left">Pheonix</span>
                            <span class="col-2 text-600 text-left">Visu+</span>
                            <span class="col-7 text-600 text-left">Visualization software with SCADA functionality. The software is suitable for every application: from a compact touch panel to an industrial PC.</span>
                            <span class="col-1 text-600 align-content-right">
                                <Button icon="pi pi-external-link" @click="externalSite('https://www.phoenixcontact.com/en-pc/industries/plcnext-technology#')" class="p-button-square p-button-success"></Button>
                            </span>
                            
                        </li>
                        <li class="grid">
                            <span class="col-2 inline-block text-900 font-medium text-left">Rockwell Automation</span>
                            <span class="col-2 text-600 text-left">FactoryTalk View SE/ME</span>
                            <span class="col-7 text-600 text-left">Standalone machine level HMI (View ME) to distributed visualization solutions (View SE).</span>
                            <span class="col-1 text-600 align-content-right">
                                <Button icon="pi pi-external-link" @click="externalSite('https://www.rockwellautomation.com/en-us/products/software/factorytalk/operationsuite/view.html')" class="p-button-square p-button-success"></Button>
                            </span>
                        </li>
                        <li class="grid">
                            <span class="col-2 inline-block text-900 font-medium text-left">Aveva</span>
                            <span class="col-2 text-600 text-left">Edge</span>
                            <span class="col-7 text-600 text-left">SCADA, HMI and IoT Edge Solution for OEMs, System Integrators and End Users.</span>
                            <span class="col-1 text-600 align-content-right">
                                <Button icon="pi pi-external-link" @click="externalSite('https://www.aveva.com/en/products/edge/')" class="p-button-square p-button-success"></Button>
                            </span>
                        </li>
                        <li class="grid">
                            <span class="col-2 inline-block text-900 font-medium text-left">Automation Direct</span>
                            <span class="col-2 text-600 text-left">C-More HMI</span>
                            <span class="col-7 text-600 text-left">Cost-efficient HMI software for touchscreen panels.</span>
                            <span class="col-1 text-600 align-content-right">
                                <Button icon="pi pi-external-link" @click="externalSite('https://www.automationdirect.com/adc/overview/catalog/hmi_(human_machine_interface)/c-more_touch_panels_ea9_series')" class="p-button-square p-button-success"></Button>
                            </span>
                        </li>
                    </ul>               
                </div>            
            </Fieldset>
            <Fieldset class="surface-ground" legend="Manufacturing Execution System" :toggleable="true" :collapsed="true">
                <div class="grid flex-wrap justify-content-center">
                    <div class="col-12 lg:col-4 p-3">
                        <div class="shadow-2 border-round surface-card mb-3 h-full flex-column justify-content-between flex">
                            <div class="p-4">
                                <div class="flex align-items-center">
                                    <span class="inline-flex border-circle align-items-center justify-content-center mr-3" style="width:100px;height:100px">
                                        <img src="img/icons/aveva-logo.png" alt="Image" class="w-full">
                                    </span>
                                    <span class="text-900 font-large text-2xl">Aveva Manufacturing Execution System</span>
                                </div>
                                <div class="text-900 my-3 text-xl font-medium">Model-Driven Manufacturing Execution System.</div>
                                <p class="mt-0 mb-3 text-700 line-height-3">A process centric approach to the digital transformation of best practices for reuse, sustainable standardisation and continuous improvement in plant and multi-site manufacturing operations.</p>
                            </div>
                            <div class="px-4 py-3 surface-100 flex justify-content-between flex-wrap">
                                <Button icon="pi pi-external-link" label="Visit Website" @click="externalSite('https://www.aveva.com/en/products/manufacturing-execution-system/')" class="p-button-rounded p-button-success"></Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fieldset>
            <Fieldset class="surface-ground" legend="PLCs" :toggleable="true" :collapsed="true">
                <div class="grid flex-wrap justify-content-center">
                    <div class="col-12 lg:col-4 p-3">
                        <div class="shadow-2 border-round surface-card mb-3 h-full flex-column justify-content-between flex">
                            <div class="p-4">
                                <div class="flex align-items-center">
                                    <span class="inline-flex border-circle align-items-center justify-content-center mr-3" style="width:200px;height:100px">
                                        <img src="img/icons/AB_Logo.svg" alt="Image" class="w-full">
                                    </span>
                                    <span class="text-900 font-large text-2xl">AB Control Logix</span>
                                </div>
                                <div class="text-900 my-3 text-xl font-medium">Allen‑Bradley® control systems help you meet complex to simple application requirements.</div>
                                <p class="mt-0 mb-3 text-700 line-height-3">With multi-disciplined control solutions built focusing on controllers and I/O and combined with attachment portfolios, you can deliver a smart, productive and secure Integrated Architecture® system in discrete, process, motion, and safety applications.</p>
                            </div>
                            <div class="px-4 py-3 surface-100 flex justify-content-between flex-wrap">
                                <Button icon="pi pi-external-link" label="Visit Website" @click="externalSite('https://www.rockwellautomation.com/en-us/products/hardware/allen-bradley/programmable-controllers.html')" class="p-button-rounded p-button-success"></Button>
                            </div>
                        </div>
                    </div>
                </div>
                <Divider />
                <div class="surface-card shadow-2 border-round p-4">
                    <div class="flex justify-content-between align-items-center mb-5">
                        <span class="text-xl text-900 font-medium">Additional Services</span>
                    </div>
                    <ul class="list-none p-0 m-0">
                        <li class="grid">
                            <span class="col-2 inline-block text-900 font-medium text-left">Siemens</span>
                            <span class="col-2 text-600 text-left">Simatic</span>
                            <span class="col-7 text-600 text-left">Powerful hardware for controlling all types of machines and plants.</span>
                            <span class="col-1 text-600 align-content-right">
                                <Button icon="pi pi-external-link" @click="externalSite('https://new.siemens.com/global/en/products/automation/systems/industrial/plc.html')" class="p-button-square p-button-success"></Button>
                            </span>
                        </li>
                        <li class="grid">
                            <span class="col-2 inline-block text-900 font-medium text-left">Pheonix</span>
                            <span class="col-2 text-600 text-left">PLCnext</span>
                            <span class="col-7 text-600 text-left">PLCnext Technology is the ecosystem for industrial automation consisting of open hardware, modular engineering software, a global community, and a digital software marketplace.</span>
                            <span class="col-1 text-600 align-content-right">
                                <Button icon="pi pi-external-link" @click="externalSite('https://www.phoenixcontact.com/en-pc/industries/plcnext-technology#')" class="p-button-square p-button-success"></Button>
                            </span>
                            
                        </li>
                    </ul>               
                </div>            
            </Fieldset>
            <Fieldset class="surface-ground" legend="Database/Reporting" :toggleable="true" :collapsed="true">
                <div class="grid flex-wrap justify-content-center">
                    <div class="col-12 lg:col-6 p-3">
                        <div class="shadow-2 border-round surface-card mb-3 h-full flex-column justify-content-between flex">
                            <div class="p-4">
                                <div class="flex align-items-center">
                                    <span class="text-900 font-large text-2xl">Microsoft SQL Server</span>
                                </div>
                                <div class="text-900 my-3 text-xl font-medium">Industry Leading Performance and Security</div>
                                <p class="mt-0 mb-3 text-700 line-height-3">Work with a flexible data platform that gives you a consistent experience across platforms and gets your innovations to market faster—you can build your apps and then deploy anywhere.</p>
                            </div>
                            <div class="px-4 py-3 surface-100 flex justify-content-between flex-wrap">
                                <Button icon="pi pi-external-link" label="Visit Website" @click="externalSite('https://www.microsoft.com/en-us/sql-server/sql-server-2019')" class="p-button-rounded p-button-success"></Button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 lg:col-6 p-3">
                        <div class="shadow-2 border-round surface-card mb-3 h-full flex-column justify-content-between flex">
                            <div class="p-4">
                                <div class="flex align-items-center">
                                    <span class="inline-flex border-circle align-items-center justify-content-center mr-3" style="width:125px;height:65px">
                                        <img src="img/icons/mysql_logo.png" alt="Image" class="w-full" @click="externalSite('https://www.mysql.com')">
                                    </span>
                                    <span class="text-900 font-large text-2xl">MySQL</span>
                                </div>
                                <div class="text-900 my-3 text-xl font-medium">World's Most Popular Open Source Database</div>
                                <p class="mt-0 mb-3 text-700 line-height-3">MySQL is fast, reliable, scalable, and easy to use. It was originally developed to handle large databases quickly and has been used in highly demanding production environments for many years.</p>
                            </div>
                            <div class="px-4 py-3 surface-100 flex justify-content-between flex-wrap">
                                <Button icon="pi pi-external-link" label="Visit Website" @click="externalSite('https://www.mysql.com')" class="p-button-rounded p-button-success"></Button>
                            </div>
                        </div>
                    </div>                    
                    <div class="col-12 lg:col-6 p-3">
                        <div class="shadow-2 border-round surface-card mb-3 h-full flex-column justify-content-between flex">
                            <div class="p-4">
                                <div class="flex align-items-center">
                                    <span class="inline-flex border-circle align-items-center justify-content-center mr-3" style="width:200px;height:100px">
                                        <img src="img/icons/TableauLogo_RGB.png" alt="Image" class="w-full">
                                    </span>
                                    <span class="text-900 font-large text-2xl">Tableau</span>
                                </div>
                                <div class="text-900 my-3 text-xl font-medium">The world’s leading analytics platform.</div>
                                <p class="mt-0 mb-3 text-700 line-height-3">Tableau is a visual analytics platform transforming the way we use data to solve problems—empowering people and organizations to make the most of their data.</p>
                            </div>
                            <div class="px-4 py-3 surface-100 flex justify-content-between flex-wrap">
                                <Button icon="pi pi-external-link" label="Visit Website" @click="externalSite('https://www.tableau.com/')" class="p-button-rounded p-button-success"></Button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 lg:col-6 p-3">
                        <div class="shadow-2 border-round surface-card mb-3 h-full flex-column justify-content-between flex">
                            <div class="p-4">
                                <div class="flex align-items-center">
                                    <span class="text-900 font-large text-2xl">Microsoft SSRS</span>
                                </div>
                                <p class="mt-0 mb-3 text-700 line-height-3">SQL Server Reporting Services is a server-based reporting platform that provides comprehensive reporting functionality.</p>
                            </div>
                            <div class="px-4 py-3 surface-100 flex justify-content-between flex-wrap">
                                <Button icon="pi pi-external-link" label="Visit Website" @click="externalSite('https://docs.microsoft.com/en-us/sql/reporting-services/create-deploy-and-manage-mobile-and-paginated-reports?view=sql-server-ver16')" class="p-button-rounded p-button-success"></Button>
                            </div>
                        </div>
                    </div>                    
                </div>
                <Divider />
                <div class="surface-card shadow-2 border-round p-4">
                    <div class="flex justify-content-between align-items-center mb-5">
                        <span class="text-xl text-900 font-medium">Additional Services</span>
                    </div>
                    <ul class="list-none p-0 m-0">
                        <li class="grid">
                            <span class="col-2 inline-block text-900 font-medium text-left">Aveva</span>
                            <span class="col-2 text-600 text-left">Historian</span>
                            <span class="col-7 text-600 text-left">Process database integrated with operations control enabling access to your process, alarm, and event history data.</span>
                            <span class="col-1 text-600 align-content-right">
                                <Button icon="pi pi-external-link" @click="externalSite('https://www.aveva.com/en/products/historian/')" class="p-button-square p-button-success"></Button>
                            </span>
                        </li>
                        <li class="grid">
                            <span class="col-2 inline-block text-900 font-medium text-left">PostgreSQL Global Development Group</span>
                            <span class="col-2 text-600 text-left">PostgreSQL</span>
                            <span class="col-7 text-600 text-left">Free and open-source relational database management system.</span>
                            <span class="col-1 text-600 align-content-right">
                                <Button icon="pi pi-external-link" @click="externalSite('https://www.postgresql.org/')" class="p-button-square p-button-success"></Button>
                            </span>
                        </li>
                        <li class="grid">
                            <span class="col-2 inline-block text-900 font-medium text-left">SQLite</span>
                            <span class="col-2 text-600 text-left">SQLite</span>
                            <span class="col-7 text-600 text-left">Small, fast, self-contained, high-reliability, full-featured, SQL database engine.</span>
                            <span class="col-1 text-600 align-content-right">
                                <Button icon="pi pi-external-link" @click="externalSite('https://sqlite.org/')" class="p-button-square p-button-success"></Button>
                            </span>
                        </li>
                        <li class="grid">
                            <span class="col-2 inline-block text-900 font-medium text-left">Microsoft</span>
                            <span class="col-2 text-600 text-left">Power BI</span>
                            <span class="col-7 text-600 text-left">Interactive data visualization software product with a primary focus on business intelligence.</span>
                            <span class="col-1 text-600 align-content-right">
                                <Button icon="pi pi-external-link" @click="externalSite('https://powerbi.microsoft.com/')" class="p-button-square p-button-success"></Button>
                            </span>
                        </li>                                        
                    </ul>               
                </div>            
            </Fieldset>
            <Fieldset class="surface-ground" legend="MISC" :toggleable="true" :collapsed="true">
                <div style="margin-bottom: 1em">
                    <Button type="button" icon="pi pi-plus" label="Expand All" @click="expandAll" />
                    <Button type="button" icon="pi pi-minus" label="Collapse All" @click="collapseAll" />
                </div>      
                <Tree :value="nodes" :expandedKeys="expandedKeys">
                    <template #default="slotProps">
                        <b>{{slotProps.node.label}}</b>
                    </template>
                    <template #url="slotProps">
                        <a :href="slotProps.node.data">{{slotProps.node.label}}</a>
                    </template>
                </Tree>         
            </Fieldset>        
        </div>
        <!-- Contact Section -->        
        <div class="surface-section mt-5">
            <div class="bg-no-repeat bg-cover p-8" style="background-image: url('img/contact-2.jpg')">
                <div class="text-white text-2xl font-medium mb-6">Contact Us</div>
                <div class="text-gray-300 line-height-3 mb-6">If you'd like more information on our company or any of the services we provide, feel free to send us an email.</div>
                <ul class="list-none p-0 m-0 mt-6 text-white">
                    <li>Continuum Automation LLC</li>
                    <li>10600 W 50th St</li>
                    <li>Shawnee, KS, USA 66203</li>
                    <li>
                        <br>
                        <i class="pi pi-inbox mr-2"></i>
                        <span>contact@continuum-automation.com</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            nodes: [
                {
                    icon: "pi pi-fw pi-cog",
                    key: "0",
                    label: "Programming",
                    children: [
                        {
                            icon: "pi pi-fw pi-cog",
                            key: "0-0",
                            label: "C#"
                        },
                        {
                            icon: "pi pi-fw pi-cog",
                            key: "0-1",
                            label: "C/C++"
                        },
                        {
                            icon: "pi pi-fw pi-cog",
                            key: "0-2",
                            label: "Python"
                        },
                        {
                            icon: "pi pi-fw pi-cog",
                            key: "0-3",
                            label: "VBA"
                        }
                    ]
                },
                {
                    icon: "pi pi-fw pi-book",
                    key: "1",
                    label: "Documentation",
                    children: [
                        {
                            data: "https://vuepress.vuejs.org/",
                            icon: "pi pi-fw pi-book",
                            key: "1-0",
                            label: "Vuepress",
                            type: "url"
                        },
                        {
                            data: "https://www.mkdocs.org/",
                            icon: "pi pi-fw pi-book",
                            key: "1-1",
                            label: "MkDocs",
                            type: "url"
                        },
                        {
                            data: "https://nuxtjs.org/",
                            icon: "pi pi-fw pi-book",
                            key: "1-2",
                            label: "Nuxt",
                            type: "url"
                        },
                        {
                            data: "https://www.gitbook.com/",
                            icon: "pi pi-fw pi-book",
                            key: "1-3",
                            label: "Gitbook",
                            type: "url"
                        },
                        {
                            data: "https://www.office.com/",
                            icon: "pi pi-fw pi-book",
                            key: "1-4",
                            label: "Microsoft Office",
                            type: "url"
                        }
                    ]
                },
                {
                    icon: "pi pi-fw pi-globe",
                    key: "2",
                    label: "Web Dev",
                    children: [
                        {
                            icon: "pi pi-fw pi-globe",
                            key: "2-0",
                            label: "ASP.NET"
                        },
                        {
                            data: "https://vuejs.org/",
                            icon: "pi pi-fw pi-globe",
                            key: "2-1",
                            label: "Vue.JS",
                            type: "url"
                        },
                        {
                            data: "https://docs.microsoft.com/en-us/aspnet/core/razor-pages/?view=aspnetcore-6.0",
                            icon: "pi pi-fw pi-globe",
                            key: "2-2",
                            label: "Razor Pages",
                            type: "url"
                        },
                        {
                            data: "https://www.djangoproject.com/",
                            icon: "pi pi-fw pi-globe",
                            key: "2-3",
                            label: "Django",
                            type: "url"
                        },
                        {
                            data: "https://primefaces.org/primevue/",
                            icon: "pi pi-fw pi-globe",
                            key: "2-4",
                            label: "PrimeVue",
                            type: "url"
                        },
                        {
                            data: "https://vuetifyjs.com/",
                            icon: "pi pi-fw pi-globe",
                            key: "2-5",
                            label: "Vuetify",
                            type: "url"
                        },
                        {
                            data: "https://angular.io/",
                            icon: "pi pi-fw pi-globe",
                            key: "2-4",
                            label: "Angular",
                            type: "url"
                        },
                        {
                            data: "https://reactjs.org/",
                            icon: "pi pi-fw pi-globe",
                            key: "2-5",
                            label: "React",
                            type: "url"
                        },
                        {
                            data: "https://en.wikipedia.org/wiki/Google_Workspace",
                            icon: "pi pi-fw pi-globe",
                            key: "2-5",
                            label: "Google Workspace",
                            type: "url"
                        }
                    ]
                },
                {
                    icon: "pi pi-fw pi-desktop",
                    key: "3",
                    label: "App Dev",
                    children: [
                        {
                            icon: "pi pi-fw pi-desktop",
                            key: "3-0",
                            label: ".NET/Core"
                        },
                        {
                            icon: "pi pi-fw pi-desktop",
                            key: "3-1",
                            label: "Qt"
                        }
                    ]
                },
                {
                    icon: "pi pi-fw pi-box",
                    key: "4",
                    label: "Equipment",
                    children: [
                        {
                            icon: "pi pi-fw pi-box",
                            key: "4-0",
                            label: "VFDs",
                            children: [
                                {
                                    icon: "pi pi-fw pi-box",
                                    key: "4-0-1",
                                    label: "Rockwell Powerflex Series"
                                },
                                {
                                    icon: "pi pi-fw pi-box",
                                    key: "4-0-2",
                                    label: "Yaskawa"
                                },
                                {
                                    icon: "pi pi-fw pi-box",
                                    key: "4-0-3",
                                    label: "Toshiba"
                                },
                                {
                                    icon: "pi pi-fw pi-box",
                                    key: "4-0-4",
                                    label: "ABB"
                                }                                
                            ]
                        }
                    ]
                },
                {
                    icon: "pi pi-fw pi-bars",
                    key: "5",
                    label: "Other",
                    children: [
                        {
                            icon: "pi pi-fw pi-desktop",
                            key: "5-0",
                            label: ".NET Framework/Core"
                        },
                        {
                            icon: "pi pi-fw pi-desktop",
                            key: "5-1",
                            label: "MQTT"
                        },
                        {
                            icon: "pi pi-fw pi-desktop",
                            key: "5-2",
                            label: "JSON"
                        },
                        {
                            icon: "pi pi-fw pi-desktop",
                            key: "5-3",
                            label: "XML"
                        },
                        {
                            icon: "pi pi-fw pi-globe",
                            key: "5-4",
                            label: "TCP/IP"
                        },
                        {
                            icon: "pi pi-fw pi-globe",
                            key: "5-5",
                            label: "HTTPS"
                        },
                        {
                            icon: "pi pi-fw pi-globe",
                            key: "5-6",
                            label: "HTML/Javascript/CSS"
                        },
                        {
                            icon: "pi pi-fw pi-desktop",
                            key: "5-7",
                            label: "DeviceNet"
                        },
                        {
                            icon: "pi pi-fw pi-desktop",
                            key: "5-8",
                            label: "Modbus"
                        },
                        {
                            icon: "pi pi-fw pi-desktop",
                            key: "5-9",
                            label: "ControlNet"
                        },
                        {
                            icon: "pi pi-fw pi-desktop",
                            key: "5-10",
                            label: "HART"
                        },
                        {
                            icon: "pi pi-fw pi-desktop",
                            key: "5-11",
                            label: "CIP"
                        }
                    ]
                }
            ],
            expandedKeys: {}
        }
    },
    methods: {
        expandAll() {
            for (let node of this.nodes) {
                this.expandNode(node);
            }

            this.expandedKeys = {...this.expandedKeys};
        },
        collapseAll() {
            this.expandedKeys = {};
        },
        expandNode(node) {
            if (node.children && node.children.length) {
                this.expandedKeys[node.key] = true;

                for (let child of node.children) {
                    this.expandNode(child);
                }
            }
        },
        externalSite(url){
            window.open(url, '_blank')
        }
    }
};

</script>
