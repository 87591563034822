/* eslint-disable */
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import PrimeVue from 'primevue/config'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import Toast from 'primevue/toast'
import ToastService from 'primevue/toastservice'
import StyleClass from 'primevue/styleclass'
import Ripple from 'primevue/ripple'
import BadgeDirective from 'primevue/badgedirective'
import Textarea from 'primevue/textarea'
import Fieldset from 'primevue/fieldset';
import Divider from 'primevue/divider';
import Tree from 'primevue/tree';

import 'primevue/resources/themes/md-light-indigo/theme.css'       //theme
import 'primevue/resources/primevue.min.css'                       //core css
import 'primeicons/primeicons.css'                                 //icons
import '/node_modules/primeflex/primeflex.css'                     //grid

const app = createApp(App)
app.use(store)
app.use(router)
app.use(PrimeVue, {ripple: true})
app.use(ToastService)

app.directive('styleclass', StyleClass)
app.directive('ripple', Ripple)
app.directive('badge', BadgeDirective)

app.component('InputText', InputText)
app.component('Button', Button)
app.component('Toast', Toast)
app.component('Textarea', Textarea)
app.component('Fieldset', Fieldset)
app.component('Divider', Divider)
app.component('Tree', Tree)

app.mount('#app')
